import * as React from 'react';

import Link from '../components/Link';
import Section from '../components/Section';
import Page from '../components/Page';

const NotFoundPage: React.FC = () => {
  return (
    <Page
      title="Pagina niet gevonden"
      description="Deze pagina werd niet gevonden."
    >
      <Section>
        <Section.Title>Pagina niet gevonden</Section.Title>
        <Section.Description>
          Bent u zeker dat u de correcte pagina hebt ingevuld?
        </Section.Description>
        <Link to="/" variant="primary">
          Ga naar startpagina
        </Link>
      </Section>
    </Page>
  );
};

export default NotFoundPage;
